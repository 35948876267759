import { FC, PropsWithChildren } from 'react';

import { ImageEx } from '../../ui';

export const ErrorPageLayout: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="relative w-full h-full sm:min-h-[800px] min-h-[600px]">
      <ImageEx
        alt="error_page_background"
        src="/assets/top/pack_section_bg.jpg"
        blurDataURL="/assets/top/pack_section_bg.jpg"
        layout="fill"
        objectFit="cover"
        className="opacity-40"
        placeholder="blur"
        priority
      />
      <div className="absolute flex flex-col items-center justify-center w-full h-full px-4 text-white">
        {children}
      </div>
    </div>
  );
};
