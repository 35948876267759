import { NextPageWithLayout } from 'next';
import Link from 'next/link';

import { PageLayout } from '../components/common/layout';
import { ErrorPageLayout } from '../components/error/layout';
import { Button, Text } from '../components/ui';

const defaultMessage = `It seems the answers you seek aren't here. Let's try looking somewhere else...`;

const NotFoundPage: NextPageWithLayout = () => {
  return (
    <ErrorPageLayout>
      <div className="py-3 text-5xl text-center uppercase px-7 font-cormorant">
        404
      </div>
      <div className="py-3 text-5xl text-center uppercase px-7 font-cormorant">
        STILL A MYSTERY
      </div>

      <Text className="max-w-[760px] text-center">{defaultMessage}</Text>
      <div className="py-9">
        <Link href="/">
          <Button type="button">BACK HOME</Button>
        </Link>
      </div>
    </ErrorPageLayout>
  );
};

NotFoundPage.getLayout = (Component) => {
  return <PageLayout title="Not Found Page">{Component}</PageLayout>;
};

export default NotFoundPage;
